import { Dialog, DialogContent, DialogTitle } from "@mui/material";

type ArtikelImageDialogProps = {
    artikelName?: string;
    imageData: string | ArrayBuffer | null;
    alt: string;
    onClose: Function;
};
export default function ArtikelImageDialog(props: Readonly<ArtikelImageDialogProps>) {
    const { artikelName, imageData, alt, onClose } = props;

    return (
        <Dialog sx={{}} open={true} onClose={() => onClose()}>
            <DialogTitle>
                <DialogTitle>{artikelName}</DialogTitle>
            </DialogTitle>
            <DialogContent>
                <img src={imageData?.toString()} alt={alt} height="100%" width="100%" />
            </DialogContent>
        </Dialog>
    );
}
