import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Snackbar, TextField } from "@mui/material";
import { useState } from "react";
import useInviteBenutzerToFirma from "./useInviteBenutzerToFirma";

export type InviteBenutzerToFirmaDialogProps = {
    firmaId: number | undefined;
    onClose: () => void;
};

export default function InviteBenutzerToFirmaDialog(props: Readonly<InviteBenutzerToFirmaDialogProps>) {
    const { loaded, error, inviteBenutzerToFirma } = useInviteBenutzerToFirma();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [showSnack, setShowSnack] = useState(false);

    return (
        <Dialog open={props.firmaId !== undefined} onClose={props.onClose}>
            <DialogTitle>Benutzer zur Firma einladen</DialogTitle>
            {!loaded && <LinearProgress></LinearProgress>}
            <DialogContent>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={() => {
                        setShowSnack(false);
                        props.onClose();
                    }}
                    open={showSnack}
                    autoHideDuration={2500}
                >
                    <Alert
                        onClose={() => {
                            setShowSnack(false);
                        }}
                        severity="success"
                        sx={{ width: "100%" }}
                    >
                        Benutzer wurde eingeladen!
                    </Alert>
                </Snackbar>
                <DialogContentText>
                    Bitte gib alle Daten für den Benutzer ein
                    {error !== "" ? (
                        <>
                            <br></br>
                            <span style={{ color: "red" }}>Ein Fehler ist aufgetreten: {error}</span>
                        </>
                    ) : null}
                </DialogContentText>
                <TextField
                    margin="dense"
                    id="firstName"
                    label="Vorname"
                    type="text"
                    fullWidth
                    variant="standard"
                    required
                    onChange={(ev) => setFirstName(ev.currentTarget.value)}
                />
                <TextField margin="dense" id="lastname" label="Nachname" type="text" fullWidth variant="standard" required onChange={(ev) => setLastName(ev.currentTarget.value)} />
                <TextField margin="dense" id="email" label="Email" type="email" fullWidth variant="standard" required onChange={(ev) => setEmail(ev.currentTarget.value)} />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Abbrechen</Button>
                <Button
                    onClick={async () => {
                        let invitedBenutzer = await inviteBenutzerToFirma(firstName, lastName, email, props.firmaId!);
                        if (invitedBenutzer !== undefined) {
                            setShowSnack(true);
                        }
                    }}
                >
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
}
