import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, TextField } from "@mui/material";
import { useState } from "react";
import useFirmen from "./useFirmen";

export type CreateFirmaDialogProps = {
    show: boolean;
    onClose: () => void;
};

export default function CreateFirmaDialog(props: Readonly<CreateFirmaDialogProps>) {
    const { loaded, error, createFirma } = useFirmen();

    const [name, setName] = useState("");
    const [nummer, setNummer] = useState("");
    const [bemerkung, setBemerkung] = useState("");

    return (
        <Dialog open={props.show} onClose={props.onClose}>
            <DialogTitle>Neue Firma anlegen</DialogTitle>
            {!loaded && <LinearProgress></LinearProgress>}
            <DialogContent>
                <DialogContentText>
                    Bitte gib alle Daten für die neue Firma an
                    {error !== "" ? (
                        <>
                            <br></br>
                            <span style={{ color: "red" }}>Ein Fehler ist aufgetreten: {error}</span>
                        </>
                    ) : null}
                </DialogContentText>
                <TextField margin="dense" id="name" label="Name" type="text" fullWidth variant="standard" required onChange={(ev) => setName(ev.currentTarget.value)} />
                <TextField margin="dense" id="nummer" label="Nummer" type="number" fullWidth variant="standard" required onChange={(ev) => setNummer(ev.currentTarget.value)} />
                <TextField
                    margin="dense"
                    id="bemerkung"
                    label="Bemerkung"
                    type="text"
                    fullWidth
                    variant="standard"
                    required
                    onChange={(ev) => setBemerkung(ev.currentTarget.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Abbrechen</Button>
                <Button
                    onClick={async () => {
                        let createdFirma = await createFirma({
                            name: name,
                            nummer: nummer,
                            bemerkungen: bemerkung,
                        });
                        if (createdFirma !== undefined) {
                            props.onClose();
                        }
                    }}
                >
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
}
