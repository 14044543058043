import { DataGridPro, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import CustomGridToolbar from "../../../layout/CustomGridToolbar";
import { Alert, LinearProgress } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { Artikel } from "@/Models/Artikel";
import { useWarenkorb } from "../Warenkorb/useWarenkorb";
import { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { config } from "../../../config";
import AuthenticatedImg from "../../../layout/AuthenticatedImage";
import WarenkorbLagerPicker from "./WarenkorbLagerPicker";
import { Lager } from "@/Models/Lager";
import { useGetArtikelOfLager } from "./useGetArtikelOfLager";
import useAuthentication from "./../../../authentication/useAuthentication";

export default function ArtikelPage() {
    const [selectedLager, setSelectedLager] = useState<Lager | undefined>(undefined);

    return (
        <div className="contentDiv">
            <WarenkorbLagerPicker selectLager={(l) => setSelectedLager(l)} />
            {selectedLager && <ArtikelList selectedLager={selectedLager} />}
        </div>
    );
}

type ArtikelListProps = {
    selectedLager: Lager;
};
export function ArtikelList(props: Readonly<ArtikelListProps>) {
    const { selectedLager } = props;
    const { firmaIdAnforderer } = useAuthentication();
    const { setCartItemCount } = useContext(AppContext);

    const { data, error, loaded } = useGetArtikelOfLager(firmaIdAnforderer, selectedLager.id);

    const { loaded: warenkorbLoaded, entries, addItem, lager: cartLager, setLager } = useWarenkorb();

    const [clickedArtikel, setClickedArtikel] = useState<Artikel | undefined>(undefined);

    useEffect(() => {
        if (selectedLager && firmaIdAnforderer) {
            setLager(selectedLager);
        }
    }, [selectedLager, firmaIdAnforderer]);

    useEffect(() => {
        if (warenkorbLoaded) {
            setCartItemCount(entries.length);
        }
    }, [warenkorbLoaded, entries, setCartItemCount]);

    const bestellbareMengen = useMemo(() => {
        let mengePerArtikel: { [id: string]: number } = {};

        if (cartLager) {
            data.forEach((a) => {
                let matchingAuftrag = a.montageAuftraege.find((a) => {
                    //only montageauftraege of the currently selected lager is relevant
                    if (a.lager.id === cartLager!.id) {
                        return true;
                    }
                    return false;
                });

                let auftragsMenge = 0;
                if (matchingAuftrag !== undefined) {
                    auftragsMenge = matchingAuftrag.anzahl;
                }

                let matchingLagerbestand = a.lagerBestaende.find((a) => {
                    //only bestand of the currently selected lager is relevant
                    if (a.lager.id === cartLager!.id) {
                        return true;
                    }
                    return false;
                });

                let lagerMenge = 0;
                if (matchingLagerbestand !== undefined) {
                    lagerMenge = matchingLagerbestand.anzahl;
                }

                let allowedAmount = auftragsMenge - lagerMenge + a.puffer;
                let bestellbar = Math.max(0, allowedAmount);
                mengePerArtikel[a.id] = bestellbar;
            });
        }

        return mengePerArtikel;
    }, [data, cartLager]);

    const columns: GridColDef<Artikel>[] = [
        {
            field: "actions",
            type: "actions",
            getActions: (p: GridRowParams<Artikel>) => {
                //if less or equal 0 can be bestelled, we must disable the action
                const disableArticleAction = bestellbareMengen[p.id] <= 0;

                let artikelInWarenkorb = entries.find((e) => e.artikel.id === p.id);
                let mengeInWarenkorb = 0;
                if (artikelInWarenkorb) {
                    mengeInWarenkorb = artikelInWarenkorb.anzahl;
                }

                return [
                    <GridActionsCellItem
                        key="add_item"
                        showInMenu={false}
                        icon={
                            <>
                                <AddShoppingCartIcon />
                                <span style={{ fontSize: "18px" }}>{mengeInWarenkorb > 0 ? mengeInWarenkorb : ""}</span>
                            </>
                        }
                        disabled={disableArticleAction}
                        label="Add"
                        onClick={() => {
                            let availableOrderAmount = bestellbareMengen[p.id];
                            let amount = prompt("Welche Anzahl soll abgerufen werden?\n" + availableOrderAmount + " Stk. verfügbar.");
                            if (amount) {
                                let parsedAmount = parseInt(amount!);
                                if (isNaN(parsedAmount)) {
                                    alert("Der eingegebene Wert ist keine Zahl!");
                                }
                                if (parsedAmount > availableOrderAmount) {
                                    alert("Diese Menge ist nicht bestellbar!");
                                } else {
                                    addItem(p.row, parsedAmount);
                                }
                            }
                        }}
                    />,
                ];
            },
        },
        {
            field: "imageData",
            headerName: "Bild",
            flex: 2,
            renderCell: (p) => {
                let article = p.row;
                if (!article.hasImage) {
                    return <></>;
                }
                return <AuthenticatedImg alt={"artikel " + p.row.id} src={config.BASE_URL + "angelo/artikel/" + p.row.id + "/image"} inList={true} artikelName={p.row.name} />;
            },
        },
        { field: "funktionsklasse", headerName: "Funktionsklasse", flex: 1.5 },
        { field: "name", headerName: "Klartext", flex: 2 },
        { field: "bauklasse", headerName: "Bauklasse", flex: 1.5 },
        { field: "artikelnummer", headerName: "Artikelnummer", flex: 1.5 },
        {
            field: "montageAuftraege",
            headerName: "Montageaufträge",
            flex: 2.5,
            renderCell: (p) => {
                let auftraege = p.row.montageAuftraege;
                let montageAuftrag = auftraege.filter((a) => a.lager.id === cartLager!.id);
                if (montageAuftrag.length === 0) {
                    return <>0</>;
                } else {
                    return <>{montageAuftrag[0].anzahl}</>;
                }
            },
        },
        {
            field: "lagerBestaende",
            headerName: "Lagerbestand",
            flex: 2.5,
            renderCell: (p) => {
                let bestandInLager = p.row.lagerBestaende.filter((b) => b.lager.id === cartLager!.id);
                if (bestandInLager.length === 0) {
                    return <>0</>;
                } else {
                    return <>{bestandInLager[0].anzahl}</>;
                }
            },
        },
        {
            field: "erlaubteMenge",
            headerName: "Erlaubte Bestellmenge",
            type: "custom",
            flex: 2,
            valueGetter: (p, row) => {
                return bestellbareMengen[row.id];
            },
        },
        { field: "puffer", headerName: "Pufferbestand", flex: 2 },
        { field: "bemerkungen", headerName: "Bemerkung", flex: 3 },
    ];

    return (
        <div className="contentDiv">
            {error !== "" && (
                <Alert severity="error">
                    <span>{"Ein Fehler ist aufgetreten! " + error}</span>
                </Alert>
            )}

            {cartLager !== undefined && (
                <DataGridPro
                    sx={{
                        ".not-orderable": {
                            opacity: 0.5,
                        },
                    }}
                    density="compact"
                    loading={!loaded}
                    getRowId={(r) => r.id}
                    rows={data}
                    columns={columns}
                    pagination
                    slots={{
                        toolbar: CustomGridToolbar,
                        loadingOverlay: LinearProgress as any,
                    }}
                    getRowClassName={(p) => {
                        if (bestellbareMengen[p.id] <= 0) {
                            return "not-orderable";
                        }
                        return "";
                    }}
                />
            )}
        </div>
    );
}
