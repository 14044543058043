import { useEffect, useState } from "react";
import axios from "../../../../ApiClient";
import { Benutzer } from "../../../administration/firmaManagement/Mitarbeiter/Benutzer";

//Use "http://localhost:3000/data/Firmen.json" to load debug sample data
const BENUTZER_URL = "/angelo/logistik/mitarbeiter/";

const useGetBenutzerDetails = (benutzerEmail: string | null) => {
  const [data, setData] = useState<Benutzer>();
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [reloadToggle, setReloadToggle] = useState(false);
  useEffect(() => {
    if (benutzerEmail === null || benutzerEmail === "") {
      return;
    }
    setLoaded(false);
    const loadData = async () => {
      try {
        const response = await axios.get<Benutzer>(BENUTZER_URL + benutzerEmail);
        const json = response.data;
        setData(json);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoaded(true);
      }
    };
    loadData();
  }, [benutzerEmail, reloadToggle]);

  const toggleReload = function () {
    setReloadToggle((reloadToggle) => !reloadToggle);
  };

  return { data, error, loaded, toggleReload };
};

export default useGetBenutzerDetails;
