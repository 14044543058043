import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useEffect, useState } from "react";

type ErrorDialogProps = {
    title: string;
    message?: string;
    error?: string;
    onClose?(): void;
};
export function ErrorDialog(props: Readonly<ErrorDialogProps>) {
    const { title, message, error, onClose } = props;
    const [showError, setShowError] = useState(true);

    useEffect(() => {
        if (onClose) {
            onClose();
        }
    }, [showError]);

    return (
        <Dialog open={showError}>
            <DialogTitle color={"error"}>{title}</DialogTitle>
            {message && <DialogContent>{message}</DialogContent>}
            {error && <DialogContent>{error}</DialogContent>}
            <DialogActions>
                <Button size="small" variant="contained" color="error" onClick={() => setShowError(false)}>
                    Schliessen
                </Button>
            </DialogActions>
        </Dialog>
    );
}
