import DeleteIcon from "@mui/icons-material/Delete";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { Alert, AlertTitle, Button, LinearProgress } from "@mui/material";
import { DataGridPro, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import CustomGridToolbar from "../../../../layout/CustomGridToolbar";
import { Benutzer } from "./Benutzer";
import InviteBenutzerToFirmaDialog from "./InviteBenutzerToFirmaDialog";
import useDeleteMitarbeiterOfFirma from "./useDeleteMitarbeiter";
import useMitarbeiterOfFirma from "./useMitarbeiterOfFirma";

type MitarbeiterListeProps = {
    firmaId: number;
};

const MitarbeiterListe = (props: Readonly<MitarbeiterListeProps>) => {
    const { firmaId } = props;
    const { loaded, error: updateError, data: users, updateMitarbeiter, getMitarbeiter } = useMitarbeiterOfFirma();
    const { result, error, deleting, deleteMitarbeiter } = useDeleteMitarbeiterOfFirma();
    const [invitationFirmaId, setInvitationFirmaId] = useState<number>();
    const [refresh, setRefresh] = useState<boolean>(true);

    const [updateErrorMsg, setUpdateErrorMsg] = useState<string>();

    const reloadMitarbeiter = () => {
        setRefresh(!refresh);
    };

    useEffect(() => {
        getMitarbeiter(firmaId);
    }, [firmaId, refresh]);

    const columns: GridColDef<Benutzer>[] = [
        {
            field: "actions",
            type: "actions",
            width: 80,

            getActions: (p: GridRowParams<Benutzer>) => {
                let actionIcon = p.row.isActive ? (
                    <ToggleOffIcon fontSize="small" color={p.row.isActive ? "success" : "warning"} titleAccess="Benutzer deaktivieren" />
                ) : (
                    <ToggleOnIcon fontSize="small" color={p.row.isActive ? "success" : "warning"} titleAccess="Benutzer aktivieren" />
                );
                return [
                    <GridActionsCellItem
                        key="activate"
                        showInMenu={false}
                        icon={actionIcon}
                        label="Aktivieren/Deaktivieren"
                        onClick={async (r) => {
                            let updatedBenutzer = await updateMitarbeiter(firmaId, p.row.email, !p.row.isActive);
                            if (updatedBenutzer === undefined) {
                                setUpdateErrorMsg("Ein Fehler ist aufgetreten! " + updateError);
                            }
                        }}
                    />,
                    <GridActionsCellItem
                        key="löschen"
                        showInMenu={false}
                        icon={<DeleteIcon fontSize="small" />}
                        label="Benutzer löschen"
                        onClick={async (r) => {
                            if (window.confirm("Mitarbeiter wirklich löschen")) {
                                await deleteMitarbeiter(firmaId, p.row.email);
                                reloadMitarbeiter();
                            }
                        }}
                    />,
                ];
            },
        },
        {
            field: "email",
            headerName: "Email",
            width: 150,
        },
        {
            field: "firstName",
            headerName: "Vorname",
            width: 100,
        },
        {
            field: "lastName",
            headerName: "Nachname",
            width: 100,
        },
    ];

    return (
        <>
            <InviteBenutzerToFirmaDialog
                firmaId={invitationFirmaId}
                onClose={() => {
                    setInvitationFirmaId(undefined);
                    reloadMitarbeiter();
                }}
            />
            {error && (
                <Alert severity="error">
                    <AlertTitle>Fehler beim löschen des Benutzers</AlertTitle>
                    {error}
                </Alert>
            )}
            {updateError && (
                <Alert severity="error">
                    <AlertTitle>Fehler beim Ändern des Status des Benutzers</AlertTitle>
                    {updateError}
                </Alert>
            )}
            <>
                <Button
                    sx={{ margin: 1 }}
                    size="small"
                    variant="outlined"
                    onClick={() => {
                        setInvitationFirmaId(firmaId);
                    }}
                >
                    Mitarbeiter hinzufügen
                </Button>
                <DataGridPro
                    sx={{ minHeight: 475 }}
                    autoHeight
                    density="compact"
                    loading={!loaded || deleting}
                    getRowId={(r) => r.email}
                    rows={users}
                    columns={columns}
                    getRowHeight={() => "auto"}
                    slots={{
                        toolbar: CustomGridToolbar,
                        loadingOverlay: LinearProgress as any,
                    }}
                />
            </>
        </>
    );
};
export default MitarbeiterListe;
