import { Artikel } from "@/Models/Artikel";
import axios from "../../../ApiClient";
import { useCallback, useState } from "react";

const ARTIKEL_URL = "/angelo/admin/artikel";

export const useCreateArtikel = () => {
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(true);

    const createArtikel = useCallback(async (artikel: Artikel) => {
        setLoaded(false);
        try {
            let response = await axios.post(ARTIKEL_URL, artikel);
            return response.data.id;
        } catch (error: any) {
            setLoaded(true);
            console.log("Error creating article:", error.response.data);
            setError(error.response?.data?.message ? error.response.data.message : error.message);
        } finally {
            setLoaded(true);
        }
    }, []);

    return { createArtikel, error, loaded };
};
