import { Button } from "@mui/material";
import Header from "./layout/Header";
import Navigation from "./layout/Navigation";
import useAuthentication from "./authentication/useAuthentication";
import { Navigate } from "react-router-dom";
import { UserRoles } from "./Models/User";

function Home() {
    const { isAuthenticated, hasRole } = useAuthentication();

    if (isAuthenticated) {
        if (hasRole(UserRoles.KOMMISIONIERER)) {
            return <Navigate to="/logistik/anforderungsbearbeitung" />;
        } else if (hasRole(UserRoles.ANFORDERER)) {
            return <Navigate to="/bestellung/artikel" />;
        } else if (hasRole(UserRoles.ADMINISTRATOR)) {
            return <Navigate to="/administration/firmen" />;
        }
    }

    return <LoginScreen />;
}

export default Home;

export function LoginScreen() {
    const { login } = useAuthentication();
    return (
        <>
            <Header />
            <Navigation />
            <div className="contentDiv">
                <p>Bitte melde dich an, um Angelo zu nutzen!</p>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        login();
                    }}
                >
                    Anmeldung
                </Button>
            </div>
        </>
    );
}
