import { useCallback, useState } from "react";
import axios from "../../../ApiClient";

const ARTIKEL_URL = "/angelo/admin/artikel/";

export const useSetArtikelImage = () => {
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(true);

  const setArtikelImage = useCallback(async (artikelId: string, imageFile: File) => {
    const formData = new FormData();
    formData.append("file", imageFile);

    setLoaded(false);
    try {
      await axios.post(ARTIKEL_URL + artikelId + "/image", formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      return true;
    } catch (error: any) {
      setLoaded(true);
      console.log("Error creating article:", error);
      setError(error.message);
      return false;
    } finally {
      setLoaded(true);
    }
  }, []);

  return { setArtikelImage, error, loaded };
};
