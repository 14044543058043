import { useState } from "react";
import axios from "../../../ApiClient";

const DELETE_FIRMA = "/angelo/admin/firmen/{id}";

export default function useDeleteFirma() {
    const [deleting, setDeleting] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [result, setResult] = useState<string>("");

    const deleteFirma = async (firmaId: string) => {
        setDeleting(true);
        setError("");
        setResult("");
        try {
            let response = await axios.delete(DELETE_FIRMA.replace("{id}", firmaId));
            console.log("Response from delete", response);
            setResult(response.data);
        } catch (error: any) {
            if (error.response.data.message !== undefined) {
                setError(error.response.data.message);
            } else {
                setError(error.message);
            }
        } finally {
            setDeleting(false);
        }
    };

    return { result, deleting, error, deleteFirma };
}
