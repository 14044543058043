import { useState } from "react";
import axios from "../../../../ApiClient";

import { Lager } from "../../../../Models/Lager";

const LAGER_OF_FIRMA_URL = "/angelo/admin/firmen/{id}/lager";

export type AddLagerToFirmaRequest = {
    firmaId: number;
    lagerNummern: string[];
    lagerIds?: number[];
};

export type LagerOfFirmaResponse = {
    firmaId: number;
    lager: Lager[];
};

const useLagerOfFirma = () => {
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState<Lager[]>([]);

    const setFirmenLager = async (firmaId: number, lagerNummern: string[]) => {
        try {
            setError("");
            setLoaded(false);
            let request = {
                firmaId: firmaId,
                lagerNummern: lagerNummern,
            } as AddLagerToFirmaRequest;

            let url = LAGER_OF_FIRMA_URL.replace("{id}", firmaId.toString());

            const response = await axios.put<LagerOfFirmaResponse>(url, request);
            const firma = response.data;
            setData(firma.lager);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoaded(true);
        }
    };

    const getLagerOfFirma = async (firmaId: number) => {
        try {
            setError("");
            setLoaded(false);

            let url = LAGER_OF_FIRMA_URL.replace("{id}", firmaId.toString());

            const response = await axios.get<LagerOfFirmaResponse>(url);
            const firma = response.data;
            setData(firma.lager);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoaded(true);
        }
    };
    return { data, error, loaded, setFirmenLager, getLagerOfFirma };
};

export default useLagerOfFirma;
